export enum RoutesPaths {
    Settings = 'settings',
    SettingsNoBack = 'SettingsNoBack',
    Dashboard = 'dashboard',
    DashboardInitial = '/?v',
    SearchResult = 'dashboard/search-results',
    SearchResultFull = 'search-results/:field/:value',
    ProductDetail = ':id',
    ProductConfigurationFull = 'configuration/:id',
    ProductListCategory = 'category',
    ProductListCategoryFull = 'category/:categoryName',
    ProductListCategorySubCategory = 'category/:categoryName/:subCategoryName',

    ProductDetailCartItem = ':id/:cartItem',
    Carts = 'carts',
    CartActive = 'carts/active',
    AllProducts = 'allproducts',
    Search = 'search/:query',
    TroubleShooting = 'troubleshooting/',
    TroubleShootingCommodity = 'troubleshooting/commodity',
    TroubleShootingCommodityGas = 'troubleshooting/commodity/gas',
    TroubleShootingCommodityPower = 'troubleshooting/commodity/power',
    TroubleShootingIvass = 'troubleshooting/ivass',
    TroubleShootingManutenzione = 'troubleshooting/manutenzione',
    OrderEntry = 'order-entry/',
    ModalitaAcquisizione = 'order-entry/dati-anagrafici/modalita-acquisizione',
    FormaAnagraficaMb = 'order-entry/dati-anagrafici/microbusiness-master-data',
    DatiGenerici = 'order-entry/dati-anagrafici/dati-generici',
    OcrBollettaUpload = 'order-entry/ocr-bolletta-upload',
    DatiContatto = 'order-entry/dati-anagrafici/dati-contatto',
    DatiTecniciAmministrativi = 'order-entry/dati-prodotto/dati-tecnici-amministrativi',
    DatiPod = 'order-entry/dati-prodotto/dati-pod',
    DatiPdr = 'order-entry/dati-prodotto/dati-pdr',
    DecretoCasa = 'order-entry/dati-prodotto/decreto-casa',
    KoCreditCheck = 'order-entry/dati-prodotto/ko-credit-check',
    PotenzaImpegnata = 'order-entry/dati-prodotto/potenza-impegnata',

    ConsumoGas = 'order-entry/dati-prodotto/consumo-gas',
    ConsumoGasMicrobusiness = 'order-entry/dati-prodotto/consumo-gas-mb',
    ConsumoLuce = 'order-entry/dati-prodotto/consumo-luce',
    CodiceAteco = 'order-entry/dati-prodotto/codice-ateco',
    DatiPagamento = 'order-entry/dati-pagamento',

    UploadDocumento = 'order-entry/upload-documento',
    InserimentoManualeDocumento = 'order-entry/inserimento-manuale-documento',

    SelezionaDocumento = 'order-entry/seleziona-documento',
    PrivacyDatiPersonali = 'order-entry/privacy/dati-personali',
    SceltaFirma = 'order-entry/firma/scelta-firma',
    FirmaPlico = 'order-entry/firma/plico',
    TipoEsecuzione = 'order-entry/firma/tipo-esecuzione',
    DatiAgentePiu = 'order-entry/firma/dati-agentepiu',
    FirmaVocalOrder = 'order-entry/firma/vocal-order',
    FirmaVocalOrderParams = 'order-entry/firma/vocal-order/:backToBO/:contractCode/:channel/:quoteId/:cartId/:telephone',
    FirmaPreviaStampa = 'order-entry/firma/previa-stampa',
    FirmaDoiFull = 'order-entry/firma/doi-full',
    FirmaGrafometrica = 'order-entry/firma/grafometrica',
    FirmaCartacea = 'order-entry/firma/contratto-cartaceo',
    FirmaOk = 'order-entry/firma/ok',
    SceltaContatto = 'order-entry/scelta-contatto',
    VerificaCodice = 'order-entry/verifica-codice/:both/:smsCode/:emailCode/:code',
    Costs = 'order-entry/costs',

    // Voltura
    TypologiesUse = 'scelta-tipologia',
    TransferTypology = 'order-entry/tipologia-di-voltura',
    ConfigPdf = 'order-entry/configurazione-punti-di-fornitura',
    transferModel = 'order-entry/modello-voltura',
    ConsumptionValidation = 'order-entry/configurazione-punti-di-fornitura/autolettura',
    MarketProfiling = 'order-entry/salvaguardia-microbusiness',
    DatesSelfreading = 'order-entry/scelta-date-autolettura',
    SignatureDate = 'order-entry/data-firma',
    DoiConfermaCliente = 'order-entry/doi-conferma-cliente',

    ThankYouPage = 'thank-you-page',
    SimpleThankYouPage = 'simple-thank-you-page',
    Login = 'login',
    ULogin = '/u/login',
    LeadRedirect = 'leadredirect',
    Error401 = 'errors/error-401',
    Error500 = 'errors/error-500',
    GenericError = 'errors/generic-error',

    BackOffice = 'back-office',

    QuoteList = 'quote-list',
    OrderList = 'order-list',
    RicercaSso = 'ricerca-sso',

    AssetReport = 'asset-report', //Vista Ferone

    QuoteDetailCard = 'quote-list-old/:id',
    QuoteDetail = 'quote-list/:id',

    OrderDetail = 'order/:id',
    UploadMp3 = 'upload-mp3',

    //DETTAGLI ASSET
    AssetDetail = 'detail',
    AssetViewSummaryData = 'view/summary-data/:id',
    AssetViewPaymentData = 'view/payment-data/:id/:supplycode/:pdf',
    AssetViewProcessData = 'view/process-data/:id',
    AssetViewTechnicalData = 'view/technical-data/:id',

    // NUOVO RIEPILOGO
    SummaryPage = 'order-entry/summaryPage',
    SummarySignature = 'order-entry/summarySignature',

    // CP
    CP = 'cp/',
    CPDeliberaResume = 'cp/delibera/resume',
    CPAdminResume = 'cp/amministrativo/resume',
    CPDeliberaThankYouPage = 'thank-you-page/:firstName/:lastName',

    // CP COMMERCIALE
    CPProductList = 'cp/allproducts',
    CPProductListDate = 'cp/allproducts/:assetId/:startDate',
    CPProductDetails = 'cp/productdetails/:productId',
    CPProductConfiguration = 'cp/productconfig/:id/:cartItem',
    // CP AMMINISTRATIVO "Retroattivo su altro prodotto"
    CPActivationDate = 'cp/activationDate',
    // CAMBIO TIPOLOGIA FORNITURA
    CTFEffectiveDate = 'ctf/effective-date',
    CTFSupplyChoice = 'ctf/supply-choice',

    // MANUTENZIONE
    Offline = 'errors/offline',
    // CESSAZIONE
    TerminationCart = 'termination/cart/:cartId',
    TerminationMortisCausa = 'order-entry/termination/mortis-causa',
    TerminationContacts = 'order-entry/termination/contacts',
    TerminationAppointment = 'order-entry/termination/appointment',
    TerminationRecap = 'order-entry/termination/recap',
    ActivationOrTerminationDate = 'order-entry/date/act-term',

    //PARTNERSHIPS
    PsFastwebPersonalData = 'order-entry/partnership/fastweb',
    PsFastwebActiveContracts = 'order-entry/partnership/fastweb/active-contract',

    // SOSPENSIONE
    SuspensionBasePath = 'suspension',
    SuspensionForm = 'suspension/suspension-form/:pdf/:accountNumber/:isAdministrative',
    SuspensionCancelForm = 'suspension/suspension-cancel-form/:pdf/:accountNumber',
    SuspensionThankYouPage = 'suspension-thank-you-page',

    // INTERRUZIONE
    InterruptionBasePath = 'interruption',
    InterruptionForm = 'interruption/interruption-form/:pdf/:accountNumber/:isAdministrative',
    InterruptionCancelForm = 'interruption/interruption-cancel-form/:pdf/:accountNumber',
    InterruptionThankYouPage = 'interruption-thank-you-page',

    // RIAPERTURA
    ResumingBasePath = 'resuming',
    ResumingForm = 'resuming/resuming-form/:pdf/:accountNumber/:isAdministrative',
    ResumingCancelForm = 'resuming/resuming-cancel-form/:pdf/:accountNumber',
    ResumingThankYouPage = 'resuming-thank-you-page',

    // CESSAZIONE AMMINISTRATIVA PER MOROSITÀ
    AdminTerminationBasePath = 'admin-termination',
    AdminTermination = 'admin-termination/:pdf/:accountNumber/:template',
    AdminTerminationThankYouPage = 'admin-termination-thank-you-page',

    // SOSPENSIONE, INTERRUZIONE, RIAPERTURA, CESSAZIONE AMMINISTRATIVA PER MOROSITÀ
    MorosityCancelForm = 'morosity/cancel-form/:pdf/:accountNumber',
    MorosityThankYouPage = 'morosity-thank-you-page',

    // STATUS
    StatusCancellation = 'status/cancellation',

    //DOMICILIAZIONE
    Domiciliation = 'domiciliation',
    DomiciliationSummary = 'domiciliation/summary',

    //SCONTI
    DiscountList = 'sconti/:accountId',
    RenewalList = 'renewal/:clientCode',

    // ATTIVAZIONE SEMPLICE
    TroubleShootingAttivazione = 'order-entry/troubleshooting/attivazione',
    DatiPdrAttivazione = 'order-entry/dati-pdr-matricola',
    AttivazioneTipologiaImmobile = 'order-entry/attivazione/tipologia-immobile',
    AttivazioneDataAppuntamento = 'order-entry/attivazione/presa-appuntamento',
    AttivazioneDatiAppuntamento = 'order-entry/attivazione/dati-appuntamento',
    ConsumoGasAttivazione = 'order-entry/dati-prodotto/consumo-gas-act-mb',

    ProductConfigurationStep = 'order-entry/productconfig/:id/:cartItem',

    // Variazione tecnica
    VariazioneTecnica = 'order-entry/tv',
    VariazioneTecnicaStart = 'allproducts/:productId/:lineItemId',
    SceltaCase = 'order-entry/tv/case-selection',
    ModalitaInvio = 'order-entry/tv/shipment-type',
    InfoDistributore = 'order-entry/tv/info-supplier',
    CostoPreventivo = 'order-entry/tv/costs-table',
    VTRiepilogo = 'order-entry/tv/recap',
    VariazioneParametri = 'order-entry/tv/params',
    VariazioneIndirizzi = 'order-entry/tv/address',
    AccettazioneImmediata = 'order-entry/tv/instant-acceptance',
    TipoPreventivoCosti = 'order-entry/tv/costs-preventivo',

    // CARICATORE MASSIVO
    BulkOrderBasePath = 'bulk-order',
    BulkOrderMain = 'main',
    BulkOrderUpload = 'upload',
    BulkOrderFileContent = 'file-content',
    BulkOrderThankYouPage = 'thank-you-page',

    // MASCHERA RILAVORABILITA'
    WorkabilityMask = 'workability',

    // NUOVO RIEPILOGO
    Summary = 'order-entry/summary',

    // NUOVA PAGINA INDIRIZZI
    IndirizziNew = 'order-entry/dati-prodotto/indirizzi-new',

    AggregationAccountSelection = 'aggregation/account-selection',
    AggregationDateSelection = 'aggregation/date-selection',
    AggregationSummary = 'aggregation/summary',
    AggregationThankYouPage = 'aggregation/thank-you',

    // APPUNTAMENTI
    MaintenanceAppointment = 'order-entry/maintenance-appointment',

    //Disattivazione CdC
    DeactivationCdC = 'deactivation/:billingAccount',

    //Aggiungi/Modifica pannello
    AddOrModifyPanel = 'adopt-panel/add-or-modify-panel',
    RemovePanel = 'adopt-panel/remove-panel',
    OrderSummaryPanel = 'adopt-panel/panel-order-summary',
    CustomerHabits = 'adopt-panel/customer-habits',
    PanelConfiguration = 'adopt-panel/panel-config',

    //Rateizzazione da D365
    BillsInstalment = 'bills-instalment',
    EditBillInstalment = 'edit',
    BillsThankYouPage = 'bills-thank-you-page',

    VariazioneParametriAteco = 'order-entry/tv/params/ateco',
    //Caring Creazione case
    CaringCreateCase = 'caring-create-case',
}

/**
 * Return a URL with params.
 * ex: cp/allproducts/:assetId => cp/allproducts/1223
 * @param path
 * @param params
 */
export const urlBuilder = (path: string, params: string[]): string => {
    if (path) {
        const baseUrl = path.split('/:')[0];
        const paramsS = params.join('/');
        if (paramsS) {
            return `${baseUrl}/${paramsS}`;
        }
    }
    return path;
};

export const urlJoiner = (v: string[]): string => {
    return v ? v.join('/') : '';
};

export const ERROR_ROUTES = [RoutesPaths.Error401, RoutesPaths.Error500];
